export const AUTHORIZATION_CALLBACK_ROUTE = '/authorization/callback'
export const REST_ROUTES = '*'

export const ID_PARAMS_ROUTE = '/:id'
export const PROJECT_PARAMS_ROUTE = ':projectId'
export const FEEDBACK_PARAMS_ROUTE = ':feedbackId'
export const INTERCEPT_PARAMS_ROUTE = ':interceptId'

export const MAIN_ROUTE = '/'
export const WORKSPACES_ROUTE = '/workspaces'
export const PROJECTS_ROUTE = '/projects'
export const INTERCEPTS_ROUTE = '/intercepts'
