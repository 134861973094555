import React, { createContext, FC, useCallback, useState } from 'react'
import { IUser } from 'src/gql/types'

type IUserContextType = {
  user: IUser | null;
  setUserState: (user: IUser) => void;
};

const initialState: IUserContextType = {
  user: null,
  setUserState: () => {},
}

export const UserContext = createContext<IUserContextType>(initialState)

type IUserProps = {
  children: React.ReactNode;
};

const UserContextProvider: FC<IUserProps> = ({ children }) => {
  const [user, setUser] = useState<IUser | null>(null)

  const setUserState = useCallback((user: IUser) => {
    setUser(user)
  }, [])

  return <UserContext.Provider value={{ user, setUserState }}>{children}</UserContext.Provider>
}

export default UserContextProvider
