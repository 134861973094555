import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetUrlSearchParam } from 'src/hooks/useGetUrlSearchParam'
import { useGenerateTokenQuery } from 'src/gql/queries/generated/generateToken.generated'
import Cookies from 'js-cookie'
import { Alert } from '@mui/material'
import { WORKSPACES_ROUTE } from 'src/constants/routes'

const AuthLayout = (): JSX.Element | null => {
  const navigate = useNavigate()
  const code = useGetUrlSearchParam('code') as string

  const { data, isSuccess, isLoading } = useGenerateTokenQuery({
    generateTokenArgs: { code },
  })

  useEffect(() => {
    if (data?.generateToken.token) {
      Cookies.set('token', data.generateToken.token)
      navigate(WORKSPACES_ROUTE)
    }
  }, [data, navigate])

  if (!isLoading && !isSuccess) {
    return (
      <Alert severity="error" data-testid="alert">
        Something went wrong!
      </Alert>
    )
  }

  return null
}

export default AuthLayout
