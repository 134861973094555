import { IconButton, Tooltip } from '@mui/material'
import React, { useMemo } from 'react'
import { LABEL } from 'src/constants/index'
import { ReactComponent as ChatIcon } from 'src/assets/svg/questionPro/chat.svg'
import { ReactComponent as UpdateIcon } from 'src/assets/svg/questionPro/update.svg'
import { CACHE_CONFIG } from 'src/constants/common'
import { useGetHeaderFooterUserInfoQuery } from 'src/gql/queries/generated/getHeaderFooterUserInfo.generated'

const Footer: React.FC = () => {
  const { data } = useGetHeaderFooterUserInfoQuery({}, CACHE_CONFIG)
  const footerData = useMemo(() => data?.getHeaderFooterUserInfo?.footer || null, [data])

  return (
    data?.getHeaderFooterUserInfo?.footer && (
      <footer className="footer">
        <div className="container">
          <div className="left">
            <div className="partners" data-testid="partner-license">
              {footerData?.licenseName}
            </div>
            <div className="copyright">
              <span>
                &#169;{new Date().getFullYear()} {footerData?.appName}
              </span>
            </div>
          </div>
          <div className="right">
            <a target="_blank" href="https://www.questionpro.com/updates/" rel="noreferrer">
              <Tooltip title={`${LABEL.WHATS_NEW}`} role={`${LABEL.WHATS_NEW}`}>
                <IconButton sx={{ borderRadius: 1 }}>
                  <UpdateIcon />
                </IconButton>
              </Tooltip>
            </a>
            <a
              target="_blank"
              href="https://www.questionpro.com/chat/?mode=launch&pageMode=quickChat"
              rel="noreferrer"
            >
              <Tooltip title={`${LABEL.CHAT_NOW}`} role={`${LABEL.CHAT_NOW}`}>
                <IconButton sx={{ borderRadius: 1 }}>
                  <ChatIcon />
                </IconButton>
              </Tooltip>
            </a>
          </div>
        </div>
      </footer>
    )
  )
}

export default Footer
