import * as Types from '../../types';

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { axiosRequest } from 'src/helpers/axiosRequest';
export type GetProductSwitcherQueryVariables = Types.IExact<{ [key: string]: never }>;

export type GetProductSwitcherQuery = {
  getProductSwitcher: Array<{
    name: string;
    active: boolean;
    order: number;
    logo: string;
    desc: string;
    products: Array<{
      name: string;
      icon: string;
      active: boolean;
      order: number;
      link: string;
      logo: string;
    }>;
  }>;
};

export const GetProductSwitcherDocument = `
    query getProductSwitcher {
  getProductSwitcher {
    name
    active
    order
    logo
    desc
    products {
      name
      icon
      active
      order
      link
      logo
    }
  }
}
    `;

export const useGetProductSwitcherQuery = <TData = GetProductSwitcherQuery, TError = unknown>(
  variables?: GetProductSwitcherQueryVariables,
  options?: UseQueryOptions<GetProductSwitcherQuery, TError, TData>
) => {
  return useQuery<GetProductSwitcherQuery, TError, TData>(
    variables === undefined ? ['getProductSwitcher'] : ['getProductSwitcher', variables],
    axiosRequest<GetProductSwitcherQuery, GetProductSwitcherQueryVariables>(
      GetProductSwitcherDocument
    ).bind(null, variables),
    options
  );
};

useGetProductSwitcherQuery.document = GetProductSwitcherDocument;

useGetProductSwitcherQuery.getKey = (variables?: GetProductSwitcherQueryVariables) =>
  variables === undefined ? ['getProductSwitcher'] : ['getProductSwitcher', variables];
