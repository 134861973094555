import { FC, ReactNode, Suspense } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import UserContextProvider from 'src/context/UserContext'
import SnackbarContextProvider from 'src/context/SnackbarContext'
import BreadCrumbContextProvider from 'src/context/BreadCrumbContext'
import { createTheme, ThemeProvider } from '@mui/material'
import Loading from 'src/components/atoms/Loading'
import { BrowserRouter as Router } from 'react-router-dom'
import Footer from 'src/components/organisms/Footer'

type IProviderProps = {
  children: ReactNode;
};

const Providers: FC<IProviderProps> = ({ children }) => {
  const queryClient = new QueryClient()
  const theme = createTheme()

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <UserContextProvider>
          <SnackbarContextProvider>
            <QueryClientProvider client={queryClient}>
              <Suspense fallback={<Loading />}>
                <BreadCrumbContextProvider>{children}</BreadCrumbContextProvider>
                <Footer />
              </Suspense>
            </QueryClientProvider>
          </SnackbarContextProvider>
        </UserContextProvider>
      </Router>
    </ThemeProvider>
  )
}

export default Providers
