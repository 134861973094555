import * as Types from '../../types';

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { axiosRequest } from 'src/helpers/axiosRequest';
export type GetMeQueryVariables = Types.IExact<{ [key: string]: never }>;

export type GetMeQuery = {
  getMe: {
    emailAddress: string;
    userId: number;
    orgId: number;
    primaryUserApiKey: string;
    firstName: string;
    lastName: string;
    isAdmin: boolean;
  };
};

export const GetMeDocument = `
    query GetMe {
  getMe {
    emailAddress
    userId
    orgId
    primaryUserApiKey
    firstName
    lastName
    isAdmin
  }
}
    `;

export const useGetMeQuery = <TData = GetMeQuery, TError = unknown>(
  variables?: GetMeQueryVariables,
  options?: UseQueryOptions<GetMeQuery, TError, TData>
) => {
  return useQuery<GetMeQuery, TError, TData>(
    variables === undefined ? ['GetMe'] : ['GetMe', variables],
    axiosRequest<GetMeQuery, GetMeQueryVariables>(GetMeDocument).bind(null, variables),
    options
  );
};

useGetMeQuery.document = GetMeDocument;

useGetMeQuery.getKey = (variables?: GetMeQueryVariables) =>
  variables === undefined ? ['GetMe'] : ['GetMe', variables];
