import React, { FC, memo } from 'react'
import { Box, CircularProgress } from '@mui/material'
import classes from './loading.module.scss'
interface ILoadingProps {
  isOpen?: boolean;
}

const Loading: FC<ILoadingProps> = ({ isOpen = true }) => {
  if (!isOpen) return null

  return (
    <Box className={classes.circularProgress} data-testid="loading">
      <div className={classes.relative}>
        <div className={classes.loadingContainer} data-testid="loading-container">
          <CircularProgress
            color="primary"
            size={30}
            thickness={5}
            className={classes.loading}
            data-testid="circular-progress"
          />
        </div>
        <div className={classes.overlay} id="overlay" />
      </div>
    </Box>
  )
}

export default memo(Loading)
