import React, { createContext, FC, useCallback, useState } from 'react'

export type ISnackbarType = {
  message?: string;
  isOpen: boolean;
  type?: 'success' | 'error' | '';
};

export type ISnackbarContextType = {
  snackbarState: ISnackbarType;
  setSnackbarState: (state: ISnackbarType) => void;
};

const initialState: ISnackbarType = {
  message: '',
  isOpen: false,
  type: '',
}

export const SnackbarContext = createContext<ISnackbarContextType>({
  snackbarState: initialState,
  setSnackbarState: (_: ISnackbarType) => {},
})

type IProps = {
  children: React.ReactNode;
};

const SnackbarContextProvider: FC<IProps> = ({ children }) => {
  const [snackbarState, setSnackbarState] = useState<ISnackbarType>(initialState)

  const setState = useCallback(
    (state: ISnackbarType) => {
      setSnackbarState(state)
    },
    [setSnackbarState]
  )

  return (
    <SnackbarContext.Provider value={{ snackbarState, setSnackbarState: setState }}>
      {children}
    </SnackbarContext.Provider>
  )
}

export default SnackbarContextProvider
