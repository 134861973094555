import { lazy } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import MainLayout from 'src/layout/MainLayout'
import AuthLayout from 'src/layout/AuthLayout'
import {
  AUTHORIZATION_CALLBACK_ROUTE,
  FEEDBACK_PARAMS_ROUTE,
  INTERCEPT_PARAMS_ROUTE,
  INTERCEPTS_ROUTE,
  MAIN_ROUTE,
  PROJECT_PARAMS_ROUTE,
  PROJECTS_ROUTE,
  REST_ROUTES,
  WORKSPACES_ROUTE,
} from 'src/constants/routes'

const Projects = lazy(() => import('src/pages/projects'))
const Project = lazy(() => import('src/pages/project'))
const Intercept = lazy(() => import('src/pages/intercept'))
const WorkSpaces = lazy(() => import('src/pages/workspaces'))

const AppRouter: React.FC = (): React.ReactElement | null => {
  const routes = useRoutes([
    {
      path: AUTHORIZATION_CALLBACK_ROUTE,
      element: <AuthLayout />,
    },
    {
      path: MAIN_ROUTE,
      element: <MainLayout />,
      children: [
        {
          path: MAIN_ROUTE,
          element: <Navigate to={WORKSPACES_ROUTE} replace />,
        },
        {
          path: WORKSPACES_ROUTE,
          element: <WorkSpaces />,
        },
        {
          path: `${WORKSPACES_ROUTE}/${FEEDBACK_PARAMS_ROUTE}${PROJECTS_ROUTE}`,
          element: <Projects />,
        },
        {
          path: `${WORKSPACES_ROUTE}/${FEEDBACK_PARAMS_ROUTE}${PROJECTS_ROUTE}/${PROJECT_PARAMS_ROUTE}${INTERCEPTS_ROUTE}`,
          element: <Project />,
        },
        {
          path: `${WORKSPACES_ROUTE}/${FEEDBACK_PARAMS_ROUTE}${PROJECTS_ROUTE}/${PROJECT_PARAMS_ROUTE}${INTERCEPTS_ROUTE}/${INTERCEPT_PARAMS_ROUTE}`,
          element: <Intercept />,
        },
      ],
    },
    {
      path: REST_ROUTES,
      element: <h1 data-testid="not-found">404 - Not Found</h1>,
    },
  ])

  return routes
}

export default AppRouter
