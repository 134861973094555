import * as Types from '../../types';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { axiosRequest } from 'src/helpers/axiosRequest';

export type GetHeaderFooterUserInfoQueryVariables = Types.IExact<{ [key: string]: never }>;

export type GetHeaderFooterUserInfoQuery = {
  getHeaderFooterUserInfo: {
    myAccount: {
      license: Types.ILicenseInfo;
      settings: Types.ISettingsInfo[];
      profile: Types.IProfileInfo;
      usage: Types.IUsageInfo;
      invoice: Types.IInvoiceInfo;
      issueTrackerCount: number;
    };
    footer: Types.IFooterInfo;
  };
};

export const GetHeaderFooterUserInfoDocument = `
  query getHeaderFooterUserInfo {
    getHeaderFooterUserInfo {
      myAccount {
        license {
          currentLicenseExpiryDays
          expiryAfterQuarter
          expiryBeforeTwoMonth
          expiryDate
          expiryDatePostFixString
          showExpiryDate
          subtitle
          title
          upgradeLink
          url
        }
        settings {
          betaIconHTML
          canDisplay
          displayIcon
          title
          url
        }
        profile {
          companyName
          initials
          profilePicture
          subtitle
          title
          url
        }
        usage {
          collectedResponseCount
          displayResponseProgressBar
          responseCountPercentage
          title
          url
        }
        invoice {
          size
          title
          url
        }
        issueTrackerCount
      }
      footer {
        appName
        canShowPerformanceMonitor
        evalOrFreeLicence
        livePersonURL
        licenseName
      }
    }
  }
`;

export const useGetHeaderFooterUserInfoQuery = <
  TData = GetHeaderFooterUserInfoQuery,
  TError = unknown,
>(
  variables?: GetHeaderFooterUserInfoQueryVariables,
  options?: UseQueryOptions<GetHeaderFooterUserInfoQuery, TError, TData>
) => {
  return useQuery<GetHeaderFooterUserInfoQuery, TError, TData>(
    variables === undefined ? ['getHeaderFooterUserInfo'] : ['getHeaderFooterUserInfo', variables],
    axiosRequest<GetHeaderFooterUserInfoQuery, GetHeaderFooterUserInfoQueryVariables>(
      GetHeaderFooterUserInfoDocument
    ).bind(null, variables),
    options
  );
};

useGetHeaderFooterUserInfoQuery.document = GetHeaderFooterUserInfoDocument;

useGetHeaderFooterUserInfoQuery.getKey = (variables?: GetHeaderFooterUserInfoQueryVariables) =>
  variables === undefined ? ['getHeaderFooterUserInfo'] : ['getHeaderFooterUserInfo', variables];
