import { createRoot } from 'react-dom/client'
import Providers from 'src/provider'
import AppRouter from 'src/router'
import 'src/styles/index.scss'
import '@questionproext/wick-ui-icons/dist/icomoon/css/wick-ui-icon.css'
import '@questionproext/wick-ui-lib/dist/esm/wick-ui-bundle.css'

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Providers>
    <AppRouter />
  </Providers>
)
