import axios, { AxiosRequestHeaders } from 'axios'
import { useContext } from 'react'
import { SnackbarContext } from 'src/context/SnackbarContext'
import { REACT_APP_BASE_URL } from 'src/constants/api'
import { snackbarHelper } from 'src/constants/snackbar'

export const apiClient = axios.create({ baseURL: REACT_APP_BASE_URL })

export const axiosRequest = <Tdata, Tvariables>(
  query: string,
  headers?: AxiosRequestHeaders
): ((variables?: Tvariables) => Promise<Tdata>) => {
  // eslint-disable-next-line
  const { setSnackbarState } = useContext(SnackbarContext);
  return async (variables?: Tvariables) => {
    try {
      const { data } = await apiClient.post<{ data: Tdata; errors: any }>(
        '',
        { query, variables },
        { headers: headers }
      )

      if (data.errors?.length) {
        throw new Error(data.errors[0].message)
      }

      return data.data
    } catch (error: any) {
      setSnackbarState({
        message: error.message,
        type: snackbarHelper.errorType,
        isOpen: snackbarHelper.open,
      })
      return error
    }
  }
}
