import React, { createContext, FC, useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { WORKSPACES_ROUTE } from 'src/constants/routes'

export type IBreadCrumbType = {
  name: string;
  link: string;
};

type IBreadCrumbContextType = {
  breadCrumbState: IBreadCrumbType[];
  addBreadCrumb: (state: IBreadCrumbType) => void;
  removeBreadCrumb: (link: string) => void;
  replaceBreadCrumbData: (_: IBreadCrumbType) => void;
  resetBreadCrumbState: () => void;
};

const initialData = sessionStorage.getItem('breadCrumbData')
const initialState: IBreadCrumbType[] = initialData ? JSON.parse(initialData) : []

export const BreadCrumbContext = createContext<IBreadCrumbContextType>({
  breadCrumbState: initialState,
  addBreadCrumb: (_: IBreadCrumbType) => {},
  replaceBreadCrumbData: (_: IBreadCrumbType) => {},
  removeBreadCrumb: (_: string) => {},
  resetBreadCrumbState: () => {},
})

type IBreadCrumbProps = {
  children: React.ReactNode;
};

const BreadCrumbContextProvider: FC<IBreadCrumbProps> = ({ children }) => {
  const [breadCrumbState, setBreadCrumbState] = useState<IBreadCrumbType[]>(initialState)
  const { pathname } = useLocation()

  const popStateHandler = useCallback(() => {
    const indexofLink = breadCrumbState.findIndex(({ link }) => link === pathname)

    if (indexofLink === -1) {
      return
    }

    if (indexofLink === 0) {
      setBreadCrumbState([])
    } else {
      sessionStorage.setItem(
        'breadCrumbData',
        JSON.stringify(breadCrumbState.slice(0, indexofLink))
      )
      setBreadCrumbState(breadCrumbState.slice(0, indexofLink))
    }
  }, [breadCrumbState, pathname])

  const resetBreadCrumbState = useCallback(() => {
    sessionStorage.setItem('breadCrumbData', JSON.stringify([]))
    setBreadCrumbState([])
  }, [])

  useEffect(() => {
    window.addEventListener('popstate', popStateHandler)
    return (): void => {
      window.removeEventListener('popstate', popStateHandler)
    }
  }, [popStateHandler])

  useEffect(() => {
    if (pathname === '/' || pathname === WORKSPACES_ROUTE) {
      resetBreadCrumbState()
    }
  }, [pathname, resetBreadCrumbState])

  const addBreadCrumb = useCallback(
    (breadCrumb: IBreadCrumbType) => {
      if (breadCrumbState.find(({ link }) => link === breadCrumb.link)) {
        return
      }
      const newBreadCrumbState = [...breadCrumbState, breadCrumb]
      sessionStorage.setItem('breadCrumbData', JSON.stringify(newBreadCrumbState))
      setBreadCrumbState(newBreadCrumbState)
    },
    [breadCrumbState]
  )

  const removeBreadCrumb = useCallback(
    (breadCrumbLink: string) => {
      const index = breadCrumbState.findIndex(({ link }) => link === breadCrumbLink) + 1
      let state = [...breadCrumbState]

      if (index > 0) {
        state.splice(index)
      } else {
        state = []
      }

      sessionStorage.setItem('breadCrumbData', JSON.stringify(state))
      setBreadCrumbState(state)
    },
    [breadCrumbState]
  )

  // const replaceBreadCrumbData = useCallback(
  //   (breadCrumb: IBreadCrumbType) => {
  //     setBreadCrumbState((prevState: IBreadCrumbType[]) => {
  //       const data = prevState.slice(0, breadCrumbState.length - 1);
  //       data.push(breadCrumb);
  //       sessionStorage.setItem("breadCrumbData", JSON.stringify(data));
  //       return data;
  //     });
  //   },
  //   [breadCrumbState]
  // );

  const replaceBreadCrumbData = useCallback((breadCrumb: IBreadCrumbType) => {
    setBreadCrumbState((prevState) => {
      const data = [...prevState.slice(0, prevState.length - 1), breadCrumb]
      sessionStorage.setItem('breadCrumbData', JSON.stringify(data))
      return data
    })
  }, [])

  return (
    <BreadCrumbContext.Provider
      data-testid={'breadCrumb'}
      value={{
        breadCrumbState,
        addBreadCrumb,
        removeBreadCrumb,
        replaceBreadCrumbData,
        resetBreadCrumbState,
      }}
    >
      {children}
    </BreadCrumbContext.Provider>
  )
}

export default BreadCrumbContextProvider
