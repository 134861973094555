import { useCallback, useContext, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Header from 'src/components/organisms/Header/index'
import Cookies from 'js-cookie'
import { GetMeQuery, useGetMeQuery } from 'src/gql/queries/generated/getMe.generated'
import { apiClient } from 'src/helpers/axiosRequest'
import CircularProgress from '@mui/material/CircularProgress'
import Snackbar from 'src/components/atoms/Snackbar'
import { CACHE_CONFIG } from 'src/constants/common'
import { Alert, Box } from '@mui/material'
import { IUser } from 'src/gql/types'
import { UserContext } from 'src/context/UserContext'

const MainLayout = (): JSX.Element => {
  const token = Cookies.get('token')
  const context = useContext(UserContext)

  apiClient.interceptors.request.use(
    function (config: any) {
      if (config.headers) {
        config.headers = {
          ...config.headers,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        }

        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
      }
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  const { data, refetch, isError, isLoading, isFetching } = useGetMeQuery<GetMeQuery, Error>(
    {},
    CACHE_CONFIG
  )

  const navigateToAuth = useCallback((): void => {
    window.location.href = `${process.env.REACT_APP_AUTHORIZATION_URL}/?state=null&redirect_uri=${process.env.REACT_APP_CALLBACK_URL}&response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}`
  }, [])

  useEffect((): void => {
    if (!token) {
      navigateToAuth()
    }
    if (token) {
      refetch()
    }
  }, [navigateToAuth, refetch, token])

  useEffect((): void => {
    if (data?.getMe) {
      context.setUserState(data.getMe as IUser)
    }
  }, [context, data])

  if (!token || isLoading || isFetching) {
    return (
      <Box className={'main-layout-loader'}>
        <CircularProgress />
      </Box>
    )
  }

  if (isError) {
    return (
      <Alert id={'mui-alert'} severity="error">
        Something Went Wrong
      </Alert>
    )
  }

  return (
    <>
      <Header />
      <div className={'main-layout'}>
        <Snackbar />
        <Outlet />
      </div>
    </>
  )
}

export default MainLayout
