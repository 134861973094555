import * as Types from '../../types';

import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { axiosRequest } from 'src/helpers/axiosRequest';
export type GenerateTokenQueryVariables = Types.IExact<{
  generateTokenArgs: Types.IGenerateTokenInput;
}>;

export type GenerateTokenQuery = { generateToken: { token: string } };

export const GenerateTokenDocument = `
    query GenerateToken($generateTokenArgs: GenerateTokenInput!) {
  generateToken(input: $generateTokenArgs) {
    token
  }
}
    `;

export const useGenerateTokenQuery = <TData = GenerateTokenQuery, TError = unknown>(
  variables: GenerateTokenQueryVariables,
  options?: UseQueryOptions<GenerateTokenQuery, TError, TData>
) => {
  return useQuery<GenerateTokenQuery, TError, TData>(
    ['GenerateToken', variables],
    axiosRequest<GenerateTokenQuery, GenerateTokenQueryVariables>(GenerateTokenDocument).bind(
      null,
      variables
    ),
    options
  );
};

useGenerateTokenQuery.document = GenerateTokenDocument;

useGenerateTokenQuery.getKey = (variables: GenerateTokenQueryVariables) => [
  'GenerateToken',
  variables,
];
