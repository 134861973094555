import { useLocation } from 'react-router-dom'

export const useGetUrlSearchParam = (param: string): string | null => {
  const useQuery = (): URLSearchParams => {
    return new URLSearchParams(useLocation().search)
  }

  const query = useQuery()
  return query.get(param)
}
